import styled, { css } from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray, grayscale } from 'src/styles/colors'

const TRANSITION_DURATION = '.5s'

const imageSize = css`
  width: 156px;
  height: 312px;
  @media ${device.desktopLG} {
    width: 214px;
    height: 404px;
  }
`

export const CarouselContainer = styled.div`
  .react-multi-carousel-track {
    .react-multi-carousel-item--active:first-of-type img {
      opacity: 1;
      ${imageSize}
    }
    .react-multi-carousel-item img {
      opacity: .2;
      width: 132px;
      height: 280px;
      transition: all ${TRANSITION_DURATION} ease;
      @media ${device.desktopLG} {
        width: 180px;
        height: 360px;
      }
    }

    .active-image img {
      opacity: 1;
      ${imageSize}
    }
  }
`

export const ButtonGroupDiv = styled.div`
  display: flex;
  gap: 24px;

  svg {
    @media ${device.tablet} {
      width: 32px;
      height: 32px;
    }
  }
`

export const CarouselStepper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Citrina';

  .carousel-stepper--current {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    color: ${grayscale[500]};
    transition: all ${TRANSITION_DURATION} ease;
  }
  .carousel-stepper {
    letter-spacing: 4px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: ${grayscale[200]};
  }
`

export const TextContentCarouselDiv = styled.div`
  h3 {
    color: ${grayscale[600]};
  }

  p {
    color: #616161;
  }

  .observation {
    margin-top: 24px;
    color: #616161;
    display: block;
    padding: 16px;
    border-radius: 16px;
    background-color: ${gray[400]};
  }

  .observation::before {
    content: "";

  }
`
