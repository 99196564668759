import React from 'react'
import Carousel, { CarouselInternalState, ResponsiveType } from "react-multi-carousel"
import { ICarouselContent, ICompoundCarouselProps, ICompoundCarouselContext } from './types'
import CompoundCarouselProvider, { useCompoundCarouselContext } from './CompoundCarouselContext'
import IconsSwitch from 'src/components/IconsSwitch/_index'

import { ImgWebp } from 'src/styles/imgWebp'
import { grayscale, orange } from 'src/styles/colors'

import * as S from './styles'

export const CompoundCarousel = ({ carouselContent, children }: ICompoundCarouselProps) => {
  return (
    <CompoundCarouselProvider carouselContent={carouselContent}>
      {children}
    </CompoundCarouselProvider>
  )
}

CompoundCarousel.ImageCarousel = () => {
  const { applyOpacityToFirstActiveItem, imageCarouselRef, setCurrentSlide, carouselContent }: ICompoundCarouselContext = useCompoundCarouselContext()
  const responsive: ResponsiveType = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  }

  const afterChange = (_: number, { currentSlide }: CarouselInternalState) => {
    setCurrentSlide(currentSlide + 1)
    applyOpacityToFirstActiveItem()
  }

  return (
    <S.CarouselContainer>
      <Carousel
        ref={imageCarouselRef}
        arrows={false}
        responsive={responsive}
        afterChange={afterChange}
      >
        {carouselContent.map((item: ICarouselContent, index: number) => (
          <div key={index} className='h-100 d-flex align-items-center'>
            <ImgWebp src={item.img.url} alt={item.img.alt} breakPointsSize={{ sm: '160px', md: '160px', lg: '212px', xl: '272px' }} />
          </div>
        ))}
        <div />
      </Carousel>
    </S.CarouselContainer>
  )
}

CompoundCarousel.CarouselStepper = () => {
  const { currentSlide }: ICompoundCarouselContext = useCompoundCarouselContext()

  return (
    <S.CarouselStepper>
      <span className='carousel-stepper--current pr-2'>{currentSlide}</span><span className='carousel-stepper'>/4</span>
    </S.CarouselStepper>
  )
}

CompoundCarousel.ButtonGroup = () => {
  const { imageCarouselRef, textContentCarouselRef, currentSlide }: ICompoundCarouselContext = useCompoundCarouselContext()

  const nextSlideHandle = () => {
    if (imageCarouselRef.current) {
      imageCarouselRef.current.next()
    }
    if (textContentCarouselRef.current) {
      textContentCarouselRef.current.next()
    }
  }

  const previousSlideHandle = () => {
    if (imageCarouselRef.current) {
      imageCarouselRef.current.previous()
    }
    if (textContentCarouselRef.current) {
      textContentCarouselRef.current.previous()
    }
  }

  return (
    <S.ButtonGroupDiv>
      <div onClick={() => {
        previousSlideHandle()
      }}
      >
        <IconsSwitch icon='ic_arrow_left' lib='interco' customPath='action-navigation/' size='LG' color={currentSlide === 1 ? grayscale[200] : orange.extra} />
      </div>
      <div onClick={() => {
        nextSlideHandle()
      }}
      >
        <IconsSwitch icon='ic_arrow_right' lib='interco' customPath='action-navigation/' size='LG' color={currentSlide === 4 ? grayscale[200] : orange.extra} />
      </div>
    </S.ButtonGroupDiv>
  )
}

CompoundCarousel.TextContentCarousel = () => {
  const { carouselContent, textContentCarouselRef }: ICompoundCarouselContext = useCompoundCarouselContext()
  const responsiveTextCarousel: ResponsiveType = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }
  return (
    <S.TextContentCarouselDiv>
      <Carousel
        ref={textContentCarouselRef}
        arrows={false}
        responsive={responsiveTextCarousel}
      >
        {carouselContent.map((item: ICarouselContent, index: number) => (
          <div key={index}>
            <h3 className='fs-16 lh-20 fs-md-20 lh-md-24 fw-600 font-sora' dangerouslySetInnerHTML={{ __html: item.title }} />
            <p className='fs-14 lh-17 fs-xl-16 lh-xl-20 fw-400 font-inter' dangerouslySetInnerHTML={{ __html: item.description }} />
            {item.observation ? <p className='fs-12 lh-15 observation' dangerouslySetInnerHTML={{ __html: item.observation }} /> : null}
          </div>
        ))}
      </Carousel>
    </S.TextContentCarouselDiv>
  )
}

export default CompoundCarousel
