import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const HeroSection = styled.section`
  a, button {
    font-weight: 700;
  }p {
    color: #616161;
  }

  .breadcrumb svg {
    width: 24px;
    height: 24px;
  }

  .breadcrumb span {
    color: #616161 !important;
    font-weight: 700 !important;
    font-size: 14px;

    @media ${device.tablet} {
      font-size: 16px;
    }

    @media ${device.desktopXL} {
      font-size: 18px;
    }
  }
`
